<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value="value"
    @update:return-value="$emit('input', dateToTimestamp(dateToIso($event)))"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="date"
        :label="label"
        :prepend-icon="prependIcon"
        :outlined="outlined"
        :dense="dense"
        readonly
        v-on="on"
        @click:clear="$emit('input', null)"
        clearable
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="activeDate"
      no-title
      scrollable
      locale="ru-RU"
    >
      <v-spacer></v-spacer>
      <v-btn
        text
        color="primary"
        @click="menu = false"
      >Закрыть
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="$refs.menu.save(date)"
      >OK
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import formatters from '@/components/mixins/formatters'

export default {
  mixins: [formatters],
  name: 'DatePicker',
  props: {
    value: Number,
    label: String,
    prependIcon: {
      type: String,
      default: 'mdi-calendar'
    },
    dense: Boolean,
    outlined: Boolean
  },
  data: function () {
    return {
      menu: false,
      date: this.timestampToIso()
    }
  },
  computed: {
    activeDate: {
      get () {
        return this.dateToIso(this.date)
      },
      set (val) {
        if (!val) return null
        const [year, month, day] = val.split('-')
        this.date = `${day}.${month}.${year}`
      }
    }
  },
  methods: {
    timestampToIso () {
      let value = null
      if (this.value) {
        let date = new Date(this.value * 1000)
        const [month, day, year] = date.toLocaleDateString('en-EN').split('/')
        value = `${day.padStart(2, '0')}.${month.padStart(2, '0')}.${year}`
      }
      return value
    },
    dateToIso (data) {
      let result = null
      if (data) {
        const [day, month, year] = data.split('.')
        result = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      }
      return result
    }
  }
}
</script>

<style scoped>

</style>
